import { Formik } from "formik";
import "./MyProfile.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { ChangePasswordSchema } from "../../../utils/ValidationSchema";
import { changePasswordAPI, exportTotalContactsAPI } from "../../../services/servicesAPI";
import { toast } from "react-toastify";
import { getUserDetailsFromLocalStorage } from "../../../utils/getToken";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
import LoaderComponent from "../../../components/loaderComponent/LoaderComponent";
import SmallLoaderComponent from "../../../components/smallLoaderComponent/SmallLoaderComponent";

const MyProfile = () => {
  const navigate = useNavigate()
  const configData = useContext(MyContext)
  const [showPasswordFieldFlag,setShowPasswordFieldFlag] = useState(false)
  const[clickedField,setClickedField]=useState(null)
  const [currPassword,setCurrPassword] = useState("")
  const [newPassword,setNewPassword] = useState("")
  const [confirmPassword,setConfirmPassword] = useState("")
  const [userDetails,setUserDetails] = useState({})
  const [loader,setLoader] = useState(false)
  const [loaderProgressBar,setLoaderProgressBar] = useState(0)

  
  setInterval(()=>{
    let temp = sessionStorage.getItem("loadingProgressBar")
    if(temp > 0){
      setLoaderProgressBar(temp)
      setLoader(true)
    }else{
      setLoader(false)
    }

  },1000)


  const handleBorderStyle =(field)=>{
    setClickedField(field === clickedField ? null : field);
  }

  const handleChangePasswordApi = async (values)=>{
    let payloadData = {
                "currentPassword":values?.currPassword,
                "newPassword":values?.newPassword
              }
    console.log("values==========>",payloadData)
    const response = await changePasswordAPI(payloadData)
    console.log("response change password=========>",response)
    if(response.success){
      toast.success(response.message)
    }else{
      toast.error(response.message)
    }
  }

  useEffect(()=>{
    let details = getUserDetailsFromLocalStorage()
    setUserDetails(details)
  },[])

  const handleTotalExportDownload = (csvData) => {
    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    
    stopInterval()
    sessionStorage.removeItem("intervalId")
    sessionStorage.removeItem("loadingProgressBar")
    setLoader(false)
  };

  const generateRandomNumber = () => {
    // Generate a random number between 10 and 100
    return Math.floor(Math.random() * (100 - 10 + 1)) + 10;
  };

  const stopInterval = () => {
    const intervalId = sessionStorage.getItem("intervalId"); // Retrieve the stored interval ID
    if (intervalId) {
      clearInterval(intervalId); // Clear the interval
      console.log(`Interval with ID ${intervalId} cleared`);
      sessionStorage.removeItem("intervalId"); // Optionally remove it from storage
    } else {
      console.log("No interval ID found in sessionStorage");
    }
  };


  
  const handleExportTotalContacts = async ()=>{
    setLoader(true)
    
    // Start the interval and store its ID
  //   const startInterval = ()=> { 
  //  let intervalId =  setInterval(() => {
  //     const randomNumber = generateRandomNumber();
  //     sessionStorage.setItem("loadingProgressBar", randomNumber);
  //     setLoaderProgressBar(randomNumber)
  //     console.log(randomNumber); // Replace this with your desired action
  //   }, 1000);
  //   sessionStorage.setItem("intervalId",intervalId)
  //   console.log("interval id",intervalId)
  // }
  // startInterval()

    const response = await exportTotalContactsAPI()
    if (response.success) {
      toast.success(response.message);
      // const csvData = response.data;
      // // console.log("csvData========>",csvData)
      // sessionStorage.setItem("csvDataFile",JSON.stringify(csvData))
      // stopInterval()
      // sessionStorage.removeItem("intervalId")
      // sessionStorage.removeItem("loadingProgressBar")
      // setLoader(false)
      // handleTotalExportDownload(csvData);
    } else {
      toast.error(response.message);
    }
    setLoader(false)

  }


  return (
    <div className="my-profile">
      
      <div className="my-profile-heading">
        <h1>{configData?.Profile_details}</h1>
      </div>
      <div style={{ height: "20px" }}></div>
      <Formik
      initialValues={{
        currPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={ChangePasswordSchema}
      onSubmit={(values)=>{
        // console.log("submit values",values)
        handleChangePasswordApi(values)
      }}
      
      >
        {({handleSubmit, errors, touched,handleChange,values}) => {
          return <div className="my-profile-content">
            <div className="my-profile-content-1">
                <div className="my-profile-content-1-1">
                    <p>{configData?.First_name}</p>
                    <input disabled value={userDetails?.firstName} placeholder=" " type="text" style={{border:`1px solid ${clickedField==='field1' ? 'black':'#EBEDF0'}`}} onClick={()=>handleBorderStyle('field1')}/>
                </div>
                <div className="my-profile-content-1-1">
                    <p>{configData?.Last_name}</p>
                    <input disabled value={userDetails?.lastName} placeholder=" " type="text" style={{border:`1px solid ${clickedField==='field2' ? 'black':'#EBEDF0'}`}} onClick={()=>handleBorderStyle('field2')}/>
                </div>
            </div>
            <div className="my-profile-content-2">
                <div className="my-profile-content-2-1">
                    <p>{configData?.Email}</p>
                    <input disabled value={userDetails?.email} placeholder=" " type="text" style={{border:`1px solid ${clickedField==='field3' ? 'black':'#EBEDF0'}`}} onClick={()=>handleBorderStyle('field3')}  />
                </div>
                
            </div>
            
            <div style={{marginBottom:"5vh"}} className="my-profile-content-2">
                <div style={{position:"relative"}} className="my-profile-content-2-1">
                  {
                    showPasswordFieldFlag ? <p> {configData?.Current_password}</p> :<p>{configData?.Password}</p>
                  }
                   
                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <input id="currPassword"  name="currPassword" value={values.currPassword} onChange={handleChange} placeholder={!showPasswordFieldFlag?"******":""} style={{width:`${showPasswordFieldFlag?"100%":`${(100-19.5)}%`}`}} type="password"  />
                   {
                    !showPasswordFieldFlag && <span onClick={()=>{setShowPasswordFieldFlag(true)}} style={{cursor:"pointer",color:"#404040",fontSize:"14px",backgroundColor:"#E5E7EB",padding:"1.9vh 2%",marginLeft:"1%",borderRadius:"4px" ,fontWeight:'600',letterSpacing:'0.5px'}}>{configData?.Change_password}</span>
                   } 
                   </div>
                   {errors.currPassword && touched.currPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-45%",fontSize:"0.8em"}}>{errors.currPassword}</p>
                      ) : (
                        <></>
                      )}
                      
                </div>
                
            </div>

            {
              showPasswordFieldFlag && <>
              <div style={{marginBottom:"5vh"}} className="my-profile-content-2">
                <div style={{position:"relative"}} className="my-profile-content-2-1">
                    <p>{configData?.New_Password}</p>
                    <input id="newPassword" name="newPassword" value={values.newPassword} onChange={handleChange} placeholder=" " type="password"   />
                    {errors.newPassword && touched.newPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-45%",fontSize:"0.8em"}}>{errors.newPassword}</p>
                      ) : (
                        <></>
                      )}
                </div>
                
            </div>
            <div style={{marginBottom:"5vh"}} className="my-profile-content-2">
                <div style={{position:"relative"}} className="my-profile-content-2-1">
                    <p>{configData?.Repeat_new_Password}</p>
                    <input id="confirmPassword" name="confirmPassword" value={values.confirmPassword} onChange={handleChange} placeholder="" type="password"   />
                    {errors.confirmPassword && touched.confirmPassword ? (
                        <p style={{color:"red",position:"absolute",bottom:"-45%",fontSize:"0.8em"}}>{errors.confirmPassword}</p>
                      ) : (
                        <></>
                      )}

                </div>
                
            </div>
              </>
            }
            {
              showPasswordFieldFlag && <div className="my-profile-content-footer" >
              <div onClick={()=> {
                // navigate('/constant-config')
                handleSubmit(values)
                }}>
                <p style={{color:'white',fontWeight:'600',fontSize:'1em',letterSpacing:'0.7px'}} >{configData?.Save_Changes}</p>
              </div> 
            </div>

            }
            
            
           
            </div>
        }}
      </Formik>
      <div style={{display:"flex",alignItems:"center"}}>
      <div onClick={()=>{handleExportTotalContacts()}} style={{backgroundColor: "#2081E3",padding: "10px 20px",borderRadius: "4px",width:"200px",cursor:"pointer"}} >
          <p style={{fontWeight:"600",fontSize:"14px",padding:"0.2em 0.1em" ,letterSpacing:'0.7px', color:"white",textAlign:"center"}}>Export total contacts</p> 
      </div>
      {/* {
        loader && <div style={{padding: "10px 20px",display:"flex", alignItems:"center"}} >
        <SmallLoaderComponent />
        <p>{loaderProgressBar ? loaderProgressBar:sessionStorage.getItem("loadingProgressBar")}%</p>
        </div>
      } */}
      {/* {
        sessionStorage.getItem("csvDataFile") && <div onClick={()=>{handleTotalExportDownload(JSON.parse(sessionStorage.getItem("csvDataFile")))}}  style={{marginLeft:"15px",backgroundColor: "#2081E3",padding: "10px 20px",borderRadius: "4px",cursor:"pointer"}} >
        <p style={{fontWeight:"600",fontSize:"14px",padding:"0.2em 0.1em" ,letterSpacing:'0.7px', color:"white",textAlign:"center"}}>Download File</p> 
        </div>
      } */}
      </div>
    </div>
  );
};

export default MyProfile;