export const handleIsCheckedOddOrEven = (value)=>{
    if(value%2==0){
        return true
    }else{
        return false
    }
}

export const returnDateFromTimestamp = (dateString)=>{
    const date = new Date(dateString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}
export const returnTimeFromTimestamp = (timeStamp)=>{
    // const timeStamp = "2024-04-12T08:43:10.674Z";
    const date = new Date(timeStamp);
    
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
// console.log("Time_>",hours,':',minutes,':',seconds,'')

// Format the time as HH:mm:ss
const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

return time;

}

// Est time calculation function

export const returnEstTimeFromTimestamp = (timeStamp)=>{
    console.log('time stamp',timeStamp)
    // const timeStamp = "2024-04-12T08:43:10.674Z";
    const date = new Date(timeStamp);
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, timeZone: 'America/New_York' };
    const estTimeString = date.toLocaleString('en-US', options);
    // console.log('est time',estTimeString)
    return estTimeString;

}

export const returnDateAndTimeFromTimestamp = (timestamp)=>{
    // const timestamp = "2024-04-12T08:43:10.674Z";
const date = new Date(timestamp);

// Extracting date components
const year = date.getFullYear();
const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding 1 because January is 0
const day = ("0" + date.getDate()).slice(-2);

// Extracting time components
let hours = date.getHours();
const minutes = ("0" + date.getMinutes()).slice(-2);
const seconds = ("0" + date.getSeconds()).slice(-2);
const milliseconds = ("00" + date.getMilliseconds()).slice(-3);

// Converting hours to 12-hour format with AM/PM
const amPM = hours >= 12 ? "PM" : "AM";
hours = hours % 12 || 12; // Adjusting 0 to 12 for midnight

// Formatted date and time
return `${month}/${day}/${year}`  +`    `+  `${hours}:${minutes} ${amPM}`;

// console.log(formattedDateTime);
}

export const convertToReadableFormat = (str) => {
    let parts = str.split(/(?=[A-Z])/);
    parts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1));
    return parts.join(' ');
}

export const convertNumberIntoUSFromat = (str) => {
    const formattedNumber = str?.toLocaleString('en-US');
    return formattedNumber;
}

export const checkArray = (data) => {
    if(Array.isArray(data?.args[3])){
        if(data?.args[3][0]?._id){
            return true
        }
    }else{
        return false
    }
}
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatDateInUs = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

export const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
  
    // Define options for the date part
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric',timeZone: 'America/New_York' };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  
    // Define options for the time part
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'America/New_York' };
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
  
    return `${formattedDate} ${formattedTime}`;
  };